<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img :src="bannerImg" alt="" class="bigPicture" />
      <div class="box">
        <div
          class="modular"
          v-for="(item, index) in $t('patentService.PS_modular')"
          :key="index"
        >
          <div v-if="index == 0" class="itemOne">
            <div
              class="oneImg settingStyle"
              :style="
                'backgroundImage:url(' + require('@/assets/img/psBJ1.png') + ')'
              "
            >
              <div>{{ $t("patentService.PS_TipTwo") }}</div>
            </div>

            <div class="title">{{ item.title }}</div>
          </div>
          <div v-if="index == 1" class="itemTwo">
            <div class="title">{{ item.title }}</div>
            <div class="twoImg">
              <img src="@/assets/img/psBJ2.png" alt="" class="imgHoverBig" />
            </div>
          </div>
          <div v-if="index == 2" class="itemTwo">
            <div class="twoImg">
              <img src="@/assets/img/psBJ3.png" alt="" class="imgHoverBig" />
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="outerTwo" v-if="qualification.length > 0">
      <div class="tipOne">{{ $t("patentService.PS_TipOne") }}</div>
      <vue-seamless-scroll
        :data="qualification"
        :class-option="options"
        class="seamless-warp"
      >
        <ul>
          <li v-for="item in qualification" :key="item.index">
            <img :src="item.image" alt="" />
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务-专利服务
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-16 15:29
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "专利服务",
          title_en: "Patent service",
          path: "",
        },
      ],
      qualification: [], // 专利资质
      bannerImg: "",
    };
  },
  computed: {
    options() {
      return {
        // 滚动速度 , 数值越大滚动越快
        step: 0.8,
        // 滚动的数据条数
        limitMoveNum: 2,
        // 鼠标点击停止滚动, 默认true
        hoverStop: true,
        // 按住鼠标停止滚动, 默认true
        openTouch: true,
        // 滚动方向 , 0 向下 , 1 向上 , 2 向左 , 3 向右
        direction: 2,
        // 单步运动停止的高度(默认是0, 无缝不停止的滚动,direction是0或1)
        // 就是滚多高停一下,再滚多高又停一下,停的时间me就是waitTi
        // 这个高度,可以F12审查元素用箭头放在字上面看li的高度是多少
        singleHeight: 32,
        // 单步运动停止的宽度(默认是0, 无缝不停止的滚动,direction是2或3)
        singleWidth: 0,
        // 单步运动停止的时间 (默认值1000ms)
        waitTime: 0,
        // 开启数据实时监控刷新dom
        openWatch: true,
      };
    },
  },
  mounted() {
    this.getQualifications();
    this.getBigPicture();
  },
  methods: {
    /**
     * 专利资质
     * 刘嘉鑫
     * 2022-8-16
     */
    getQualifications() {
      this.$request({
        url: "/Index/honorList",
      }).then((res) => {
        console.log("专利资质", res);
        this.qualification = res.honor_list.zhuanli;
      });
    },
    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-25
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgTwo",
        data: {
          type: 3, // 类型:1=人才培养,2=研发服务,3=专利服务,4=研发条件,5=科研成果,6=研发方向,7=部门设置
        },
      }).then((res) => {
        console.log("大图", res);
        this.bannerImg = res.img;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px;
  background: #f6f6f6;
}

.bigPicture {
  width: 100%;
  height: 400px;
}

.box {
  padding: 80px 50px 20px;
}

.modular {
  > .itemOne,
  .itemTwo {
    display: flex;
    margin-bottom: 60px;

    .twoImg {
      width: 600px;
      height: 320px;
      overflow: hidden;
      background: #1a2a60;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    > .title {
      background: #ffffff;
      padding: 10px 60px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #222222;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44%;
    }
  }

  .oneImg {
    width: 300px;
    height: 320px;
    display: flex;
    align-items: center;
    padding-left: 300px;
    > div {
      font-size: 30px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #ffffff;
      // margin: 0 0 8px 130px;
      // margin: auto auto;
      padding-bottom: 7px;
    }
  }
}

.outerTwo {
  background: #1a2a60;
  padding: 80px 0 120px;

  .tipOne {
    padding: 0 310px 70px;
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #ffffff;
  }

  //   .scrollList {
  //     overflow: hidden;
  //     height: 229px;
  //     li {
  //       margin-top: 5px;
  //       background-color: aqua;
  //     }
  //     .scrollData {
  //       width: 100%;
  //       display: flex;
  //       align-items: center;
  //       > img {
  //         width: 508px;
  //         height: 360px;
  //         margin: 0 80px;
  //       }
  //     }
  //   }
  .seamless-warp {
    height: 360px;
    overflow: hidden;
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    li {
      list-style: none;
      img {
        width: 508px;
        height: 100%;
        margin: 0 80px;
      }
    }
  }
}
</style>